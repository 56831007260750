
import React from "react";
import Particles from "react-particles-js";
import styled from 'styled-components';

const partParams = {
  "particles":
  {
    "number":
    {
      "value": 100,
      "density": { "enable": true, "value_area": 800 }
    }, "color": { "value": "#ffffff" },
    "shape": {
      "type": "circle", "stroke": { "width": 0, "color": "#000000" },
      "polygon": { "nb_sides": 5 },
      "image": { "src": "img/github.svg", "width": 100, "height": 100 }
    },
    "opacity": { "value": 0.5, "random": false, "anim": { "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false } },
    "size": { "value": 3, "random": true, "anim": { "enable": false, "speed": 40, "size_min": 0.1, "sync": false } },
    "line_linked": { "enable": true, "distance": 150, "color": "#ffffff", "opacity": 0.4, "width": 1 },
    "move": { "enable": true, "speed": 6, "direction": "none", "random": false, "straight": false, "out_mode": "out", "bounce": false, "attract": { "enable": false, "rotateX": 600, "rotateY": 1200 } }
  },
  "interactivity":
  {
    "detect_on": "window",
    "events":
    {
      "onhover": { "enable": true, "mode": "repulse" },
      "onclick": { "enable": true, "mode": "push" }, "resize": true
    },
    "modes": {
      "grab": { "distance": 400, "line_linked": { "opacity": 1 } },
      "bubble": { "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3 },
      "repulse": { "distance": 200, "duration": 0.4 }, "push": { "particles_nb": 4 },
      "remove": { "particles_nb": 2 }
    }
  }, "retina_detect": true
}

const PartWrapper = styled.div`
  position: fixed;
  color: white;
  width:100%;
  top: 0px;
  height: 100vh;
  // height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in;
  opacity: ${props => props.isVisible ? 1 : 0}
  background-color: ${props => props.theme.blue};

`



const Particle = (props) => {

  const { isVisible = true } = props

  return (
    <PartWrapper isVisible={isVisible}>
      <Particles
        width={'100vw'}
        height={'100vh'}
        params={partParams} />
    </PartWrapper>
  );
};

export default Particle;